import React from "react";
import { Flex, Link, Image } from "@chakra-ui/react";
import logo from "../../assets/images/logo.svg";

const LogoHeader = () => {
  return (
    <>
      <Flex zIndex={4}>
        <Link href="/">
          <Image w={"100px"} src={logo} alt="Logo Nathan Martins" />
        </Link>
      </Flex>
    </>
  );
};

export default LogoHeader;
