import React from "react";
import { Stack, Heading, Text } from "@chakra-ui/react";

const AboutText = () => {
  return (
    <Stack
      direction="column"
      spacing={6}
      justifyContent="center"
      maxW="650px"
      px="5"
      textAlign={{ base: "center", md: "left" }}
    >
      <Heading color="#DD9331" fontSize="40px" lineHeight={1} fontWeight="bold">
        Hey There!
      </Heading>
      <Text lineHeight="1.375" fontWeight="400" color="#F9F0DF">
        I am Nathan, a Brazilian advertiser currently based in Amsterdam. With a strong background
        in
        <strong> visual and graphic designing </strong>, I specialized in creating
        <strong> visually appealing content </strong> for a multitude of media platforms. My
        expertise also extends to
        <strong> UX/UI designing</strong> and <strong> front-end development</strong>, focused on
        enabling effective and responsive layouts. Additionally, I have vast experience in
        <strong> video production </strong> and <strong> motion graphics </strong>, allowing me to
        create ever-engaging and dynamic pieces.
        <br />
        <br />I am proficient in utilizing top tier creation tools such as{" "}
        <strong>
          Figma, Adobe XD, Photoshop, InDesign, Illustrator, Premiere, and After Effects.{" "}
        </strong>
        Over the years, I have garnered
        <strong> extensive experience working with a diverse set of fields in the industry</strong>,
        including Retail, Healthcare, IT, Digital Marketing, Civil Engineering/Architecture, and
        Events Production - to name a few.
        <br />
        <br />
        Hiring me, you are getting a <strong>confident, adaptable and accommodating</strong> team
        member, as my decision-making approach ultimately led myself and any project I've worked
        with to successful outcomes. To foster innovation and go above and beyond expectations, I am
        constantly striving to
        <strong> stay ahead of the curve on industry trends </strong> and{" "}
        <strong> proactively acquire new skills</strong>.
      </Text>
    </Stack>
  );
};

export default React.memo(AboutText);
