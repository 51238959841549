import React from "react";
import { Box, Button, Card, CardBody, CardHeader, Flex, Heading, Stack } from "@chakra-ui/react";
import { BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import ContactForm from "./ContactForm";

const ContactLink = ({ href, icon: Icon, label, ...props }) => (
  <Button
    w="full"
    bg="gray.100"
    color="gray.700"
    _hover={{ bg: "orange.400", color: "white" }}
    leftIcon={<Icon />}
    transition="all 0.2s"
    onClick={() => {
      window.location.href = href;
    }}
  >
    {label}
  </Button>
);

const LinksBox = () => {
  return (
    <Flex justify="center" align="center" p={4}>
      <Card w="full" maxW="2xl" bg="whiteAlpha.600" backdropFilter="blur(10px)">
        <CardHeader>
          <Heading as="h3" size="lg" textAlign="center" color="gray.800">
            Let's Get in Touch
          </Heading>
        </CardHeader>

        <CardBody>
          <Stack spacing={6}>
            <Flex direction={{ base: "column", md: "row" }} gap={2}>
              <ContactLink
                href="mailto:nathanmls@hotmail.com"
                icon={MdEmail}
                label="nathanmls@hotmail.com"
              />
              <ContactLink
                href="https://www.linkedin.com/in/nathanmls/"
                icon={BsLinkedin}
                label="LinkedIn"
              />
              <ContactLink href="https://wa.me//31627293829" icon={BsWhatsapp} label="WhatsApp" />
            </Flex>

            <Box h="1px" bg="gray.200" />

            <ContactForm buttonbg="#DD9331" butoncolor="#ffffff" />
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default LinksBox;
