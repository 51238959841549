import React, { useState, useEffect } from "react";
import { Flex, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function CarouselHero() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    "./header-imgs/app-wippe.png",
    "./header-imgs/Logo-Meu-Credito-Liberado.png",
    "./header-imgs/Site-CleverPing.png",
    "./header-imgs/Site-Endolog.png",
    "./header-imgs/Site-Meu-Credito-Liberado.png",
    "./header-imgs/site-wippe.png",
  ];

  // Auto-cycle effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((current) => (current + 1) % images.length);
    }, 5000); // Match the original autoplaySpeed of 5000

    return () => clearInterval(interval);
  }, [images.length]);

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <Flex
      maxW={{ base: "100vw", md: "700px" }}
      boxSize={"full"}
      position="relative"
      overflow="hidden"
      height={"100%"}
      width={"100%"}
      zIndex={9}
    >
      {images.map((image, index) => (
        <motion.div
          key={index}
          style={{
            position: index === currentImageIndex ? "relative" : "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            top: 0,
            left: 0,
          }}
          initial="enter"
          animate={index === currentImageIndex ? "center" : "exit"}
          variants={variants}
          transition={{
            opacity: { duration: 0.5 }, // Match the original speed of 500
            ease: "easeInOut",
          }}
        >
          <Flex p={10} display="flex" justifyContent="center" h="500px" w="500px">
            <Image
              src={image}
              alt={`hero-${index + 1}`}
              objectFit="contain"
              width="100%"
              height="100%"
            />
          </Flex>
        </motion.div>
      ))}
    </Flex>
  );
}
