import { ChakraProvider } from "@chakra-ui/react";
import Header from "../components/molecules/Header";
import Hero from "../components/organisms/Hero";
import About from "../components/organisms/About";
import Journey from "../components/organisms/Journey";
import Showcase from "../components/organisms/Showcase";
import Contact from "../components/organisms/Contact";
import Footer from "../components/molecules/Footer";
import "../styles/home.styles.css";
import PreLoader from "../components/PreLoader";

function HomePage() {
  return (
    <>
      <PreLoader />
      <ChakraProvider>
        <Header />
        <Hero />
        <About />
        <Journey />
        <Showcase />
        <Contact />
        <Footer />
      </ChakraProvider>
    </>
  );
}

export default HomePage;
