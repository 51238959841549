// Trainee.jsx
import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const Trainee = () => {
  const words = [
    { text: "Marketing Trainee", company: "IK Events & Productions", year: 2015 },
    { text: "Marketing Trainee", company: "Vetta Group", year: 2014 },
    { text: "Marketing Trainee", company: "Gebauer", year: 2013 },
    { text: "Administration Trainee", company: "CDL Contagem", year: 2012 },
    { text: "Graphic Design Trainee", company: "ESMU - Music School", year: 2012 },
    { text: "Computer Instructor Trainee", company: "Cedaspy", year: 2009 },
  ];

  // Duplicate items to make an infinite loop effect
  const sliderWords = [...words, ...words];

  return (
    <Box py={5} pos="relative" w="full" overflow="hidden">
      <motion.div
        animate={{
          x: ["0%", "-100%"], // Move from start to end of the container
        }}
        transition={{
          repeat: Infinity,
          duration: 40, // Slow scroll speed to the right
          ease: "linear",
        }}
        style={{ display: "flex" }}
      >
        {sliderWords.map((word, index) => (
          <Box key={`${word.text}-${word.year}-${index}`} flex="0 0 25%">
            <Box pt={10} h={48}>
              <Text ml={2} color="#F9F0DF" fontSize={22} fontWeight="800">
                {word.year}
              </Text>
              <Box pos="relative" flex="items-center" my={3}>
                <Box w="full" h={1} bg="#F9F0DF" />
                <Box
                  pos="absolute"
                  ml={2}
                  top={-2}
                  w={5}
                  h={5}
                  borderRadius="full"
                  border="4px solid #F9F0DF"
                  bgColor="#115945"
                />
              </Box>
              <Text ml={2} color="#DD9331" fontSize="2xl" lineHeight={1} fontWeight="700">
                {word.text}
              </Text>
              <Box lineHeight={1} ml={2} pt={1} fontSize="lg" fontWeight="bold" color="#F9F0DF">
                {word.company}
              </Box>
            </Box>
          </Box>
        ))}
      </motion.div>
    </Box>
  );
};

export default Trainee;
