import React, { useEffect } from "react";
import { preLoaderAnim } from "../animations";
import "../styles/preloader.scss";
import logon from "../assets/logo-icons/n.svg";
import logoa from "../assets/logo-icons/a.svg";
import logot from "../assets/logo-icons/t.svg";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);

  return (
    <div className="preloader">
      <div className="logo-container">
        <div>
          <img alt="Logo N" style={{ height: "40px" }} src={logon} />
        </div>
        <div style={{ marginLeft: "5px" }}>
          <img alt="Logo A" style={{ height: "40px" }} src={logoa} />
        </div>
        <div style={{ marginLeft: "-12px" }}>
          <img alt="Logo T" style={{ height: "40px" }} src={logot} />
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
