import React from "react";
import * as ReactDOMClient from "react-dom/client"; // Change here
import "./styles/globals.css";
import { BrowserRouter } from "react-router-dom";
import RoutesPages from "./RoutesPages";

const root = ReactDOMClient.createRoot(document.getElementById("root")); // Update here
root.render(
  <BrowserRouter>
    <RoutesPages />
  </BrowserRouter>
);
