import React from "react";
import { Stack, Flex, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import JourneyData from "../atoms/JourneyData";
import CarouselJourney from "../atoms/CarouselJourney";
import Card from "../atoms/CardJourney";
import Trainee from "../atoms/Trainee";

const Journey = () => {
  return (
    <>
      <Box id="journey" bg={"url('./noise-bg.png') #115945"}>
        <motion.div
          style={{ opacity: 0, y: 40 }}
          transition={{ ease: "linear", duration: 1 }}
          whileInView={{ opacity: 1, y: "0" }}
        >
          <Flex zIndex={99} bg={"#F9F0DF"} mx={{ base: 4, md: 10 }} rounded={"15px"}>
            <Stack w={"100%"} direction={["column", "column"]} spacing={100}>
              {JourneyData.map((JourneyDataItem) => (
                <Stack
                  p={{ base: 6, md: 8 }}
                  flexDir={{ base: "column", lg: "row" }}
                  key={JourneyDataItem.id}
                  spacing={{ base: 5, md: 10 }}
                >
                  {/* Desktop view(left card) */}
                  <motion.div
                    style={{ opacity: 0 }}
                    viewport={{ amount: 0.5 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Flex
                      w={{ base: "100%", sm: "100%", md: "100%", lg: "50vw", xl: "50vw" }}
                      bg="transparent"
                      h={"auto"}
                      zIndex={9}
                      flexDirection={"column"}
                      justifySelf={"center"}
                    >
                      <CarouselJourney {...JourneyDataItem} />
                    </Flex>
                  </motion.div>
                  <motion.div
                    style={{ opacity: 0 }}
                    viewport={{ amount: 0.5 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Box>
                      <Card {...JourneyDataItem} />
                    </Box>
                  </motion.div>
                </Stack>
              ))}
            </Stack>
          </Flex>
        </motion.div>

        <Trainee />
      </Box>
    </>
  );
};

export default Journey;
