import { motion } from "framer-motion";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  Card,
  CardBody,
} from "@chakra-ui/react";
import React from "react";
import grainbgboxcont from "../../assets/images/grain-box-contact.png";
import grainbgbox from "../../assets/images/grain-box.png";
import ContactForm from "../molecules/ContactForm";
import { BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const ContactLink = ({ href, icon: Icon, label, ...props }) => (
  <Button
    w="full"
    bg="gray.100"
    color="gray.700"
    px={{ base: 6, xl: 8 }}
    _hover={{ bg: "#DD9331", color: "white" }}
    leftIcon={<Icon />}
    transition="all 0.2s"
    onClick={() => {
      window.location.href = href;
    }}
  >
    {label}
  </Button>
);
export default function ContactFormWithSocialButtons() {
  return (
    <Flex
      bg={useColorModeValue("#F9F0DF", "#F9F0DF")}
      w={"full"}
      align={"center"}
      justify={"flex-end"}
      id="contact"
      flexDir={"row"}
      px={{ base: 5, md: 16 }}
      py={{ base: 100, md: 200 }}
      mb={{ base: 10, md: 0 }}
    >
      <Box
        w={"full"}
        bgGradient={{
          base: "linear(to-r, #DD9331, #DD9331)",
          md: "linear(to-r, #DD9331 60%, #b17528 70%)",
        }}
        borderRadius="25px"
      >
        <Stack
          pos={"relative"}
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 4, md: 8, lg: 8 }}
          px={{ base: 0, md: 10 }}
        >
          <Stack
            spacing={{ base: 4, md: 8, lg: 20 }}
            direction={{ base: "row", md: "row" }}
            w={"100%"}
            pt={{ base: 5, md: 6 }}
            pb={{ base: 10, md: 6 }}
            justifyContent={{ base: "center", md: "start" }}
            align={{ base: "center", md: "center" }}
          >
            <motion.div
              initial={{ textShadow: "0 0 rgba(0, 0, 0, 0.8)" }}
              viewport={{ amount: 0.5 }}
              transition={{ duration: 0.5 }}
              whileInView={{
                textShadow: "1vw 1vw rgba(0, 0, 0, 0.1)",
                transition: { duration: 0.5, ease: "easeInOut", delay: 0.5 },
              }}
            >
              <Heading
                fontSize={{
                  base: "4xl",
                  md: "6xl",
                  lg: "8xl",
                }}
                fontWeight={900}
                textTransform={"uppercase"}
                color={"#333333"}
              >
                <Box display={{ base: "none", md: "block" }}>
                  Let's
                  <br />
                  Get in
                  <br />
                  Touch
                </Box>
                <Box textAlign={"center"} display={{ base: "block", md: "none" }}>
                  Let's Get in Touch
                </Box>

                <Box display={{ base: "none", md: "block" }}></Box>
              </Heading>
            </motion.div>
          </Stack>
          <Box
            bgRepeat={{ base: "repeat-x", md: "repeat-y" }}
            bgImage={{ base: grainbgbox, md: grainbgboxcont }}
            blendMode={"multiply"}
            bgPos={"50% 100%"}
            pos={"absolute"}
            w={"100%"}
            h={"100%"}
            roundedBottom={{ base: 25, md: 0 }}
          ></Box>
          <Stack
            spacing={{ base: 4, md: 8, lg: 10 }}
            direction={{ base: "column", md: "row" }}
            w={"100%"}
          >
            <Flex
              mx={{ base: 10, md: 0 }}
              my={{ base: -8, md: -12 }}
              justify="center"
              align="center"
              w={{ base: "auto", md: "full" }}
            >
              <Card
                color={useColorModeValue("gray.700", "whiteAlpha.900")}
                justifyContent={"center"}
                flexDir={"column"}
                bg={useColorModeValue("white", "gray.700")}
                borderRadius="15px"
                maxW="2xl"
                h={"full"}
                shadow="xl"
                w={"100%"}
              >
                <CardBody alignContent={"center"} justifyContent={"center"}>
                  <Stack spacing={6}>
                    <Flex justify={"center"} direction={{ base: "column", lg: "row" }} gap={2}>
                      <ContactLink
                        href="mailto:nathanmls@hotmail.com"
                        icon={MdEmail}
                        label={"Email"}
                      />
                      <ContactLink
                        href="https://www.linkedin.com/in/nathanmls/"
                        icon={BsLinkedin}
                        label="LinkedIn"
                      />
                      <ContactLink
                        href="https://wa.me//31627293829"
                        icon={BsWhatsapp}
                        label="WhatsApp"
                      />
                    </Flex>

                    <Box h="1px" bg="gray.200" />

                    <ContactForm buttonbg="#115945" butoncolor="#ffffff" />
                  </Stack>
                </CardBody>
              </Card>
            </Flex>
          </Stack>
        </Stack>
      </Box>
    </Flex>
  );
}
