import { Text, Flex, Box, ChakraProvider, Heading, Button, Icon } from "@chakra-ui/react";
import Footer from "../components/molecules/Footer";
import HillsDivider from "../components/atoms/HillsDivider";
import LogoHeader from "../components/atoms/Logo";
import { BsArrowLeftShort } from "react-icons/bs";

const NotFoundPage = () => {
  return (
    <>
      <ChakraProvider>
        <Flex m={"20px 0 40px"} justifyContent={"center"}>
          <LogoHeader />
        </Flex>
        <Flex mt={100} textAlign={"center"} align={"center"} justify={"center"} flexDir={"column"}>
          <Heading zIndex={4} className="hero-title" as="h1" size="2xl">
            Error 404
            <span className="highlight">
              <br />
              How'd you get here?
            </span>
          </Heading>
          <Text zIndex={4} m={"20px 25px"} maxW={"600px"} fontSize={"xl"}>
            It look like this page doesn't existing - sorry about that!
          </Text>
        </Flex>
        <Box m={"25px 0 300px"}>
          <Flex pos={"relative"} justifyContent={"center"} alignItems={"center"} zIndex={4}>
            <Button
              color={"#1D4036"}
              bgColor={"#E2E8E5"}
              border={"3px solid #1D4036"}
              borderRadius={"50px"}
              p={6}
              _hover={{ bgColor: "#1D4036", color: "#fff" }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <Icon fontSize={"30px"} mr={1} as={BsArrowLeftShort} />
              Back to Home
            </Button>
          </Flex>
        </Box>
        <HillsDivider zindex={0} m={"-300px 0 0 0"} />
        <Box mt={"417px"}>
          <Footer />
        </Box>
      </ChakraProvider>
    </>
  );
};

export default NotFoundPage;
