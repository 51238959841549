import React from "react";
import { Flex, Text, chakra, Stack, Grid, GridItem } from "@chakra-ui/react";
import { motion } from "framer-motion";
import curve from "../../assets/images/curve.svg";
import grainbg from "../../assets/images/grain-box.png";
import grainsun from "../../assets/images/grain-bg-sun.png";

const TextWithLineBreaks = ({ text }) => {
  return (
    <Text whiteSpace="pre-line" lineHeight="1.5" fontSize={{ base: "md", md: "md" }}>
      {text}
    </Text>
  );
};
const Image = ({ imgname, title, text, altimg, colspan, rowspan, direc }) => {
  return (
    <>
      <GridItem colSpan={{ base: 3, lg: colspan }} rowSpan={{ base: 3, md: rowspan }}>
        <motion.div
          whileInView={{ opacity: 1, y: "0" }}
          transition={{ ease: "linear", delay: 0.4 }}
          style={{ opacity: 0, y: "2vw" }}
          viewport={{ once: true }}
        >
          <Stack
            flexDir={{ base: "column", lg: direc }}
            alignSelf={"start"}
            alignItems={"center"}
            bg={"#F9F0DF"}
            p={10}
            rounded={20}
            spacing={10}
          >
            <Flex minW={"20vw"} h={"100%"} align={"center"} justify="center">
              <Flex zIndex={2}>
                <img src={`/port-imgs/showcase/${imgname}.png`} alt={`${altimg}`} />
              </Flex>
              <Flex
                zIndex={1}
                pos={"absolute"}
                h={"full"}
                w={{ base: "100%", md: "100%" }}
                bgSize={400}
                bgRepeat={"no-repeat"}
                bgPosition={"center center"}
                opacity={0.3}
                backgroundImage={grainsun}
              ></Flex>
            </Flex>

            <Flex zIndex={2} h={"100%"} justifyContent={"center"} direction={"column"}>
              <Text as="h3" mb="10px">
                {title}
              </Text>
              <TextWithLineBreaks text={text} />
            </Flex>
          </Stack>
        </motion.div>
      </GridItem>
    </>
  );
};
const Showcase = () => {
  return (
    <>
      <Flex
        id="showcase"
        align={"center"}
        justify={"center"}
        height={"100px"}
        color={"#1D4036"}
        bg={"url('./noise-bg.png') #DD9331"}
        boxShadow={"0px 10px 0px 0px #9d6215"}
        zIndex={9999}
        mb={"10px"}
      >
        <chakra.h2 fontSize="4xl" fontWeight="bold" textAlign="center">
          ShowCase
        </chakra.h2>
      </Flex>
      <Flex
        bg={`url(${grainbg}) #1D4036`}
        bgPosition={{ base: "100% 105%", lg: "100% 115%" }}
        bgRepeat={"repeat-x"}
        bgBlendMode={"multiply"}
      >
        <Grid
          p={{ base: "40px 20px 200px 20px", md: "60px 40px 150px 40px" }}
          h="auto"
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          gap={10}
        >
          {[
            {
              direc: "row",
              imgname: "wippe-track",
              altimg: "Wippe Track",
              rowspan: 1,
              colspan: 2,
              title: "Wippe Track",
              text: `Wippe Track is a health app developed for remote patient monitoring. It helps users control their health from a wide range of data sources, connected to devices integrated into the solution or also entered manually.
        As an interface designer and web developer, my contribution to the Wippe Track project focused on creating a user-friendly interface and developing the interface of the app and website. I worked to ensure that the app is not only functional but also easy to navigate, allowing users to monitor their health in a simple and intuitive way.
        `,
            },

            {
              direc: "column",
              imgname: "mateus-app",
              altimg: "Mateus App",
              rowspan: 2,
              colspan: 1,
              title: "Mateus App",
              text: `Mateus Mais is a shopping app developed by Grupo Mateus S/A that offers exclusive promotions from the Mateus Supermercados, Mix Atacarejo, and Camiño chains, as well as news from the brands Bumba Meu Pão and Spazio. With the Mateus Mais app, users can make purchases online and opt for easy delivery of thousands of products from their stores or pick up at the store.
        As a UX/UI Designer, my contribution to the Mateus Mais project focused on creating an intuitive and visually appealing interface for the app. I worked to ensure that the app is not only functional but also easy to navigate, allowing users to easily browse and buy products from various categories such as grocery, cold cuts, meats, vegetables, drinks, hygiene and beauty products, stationery, bazaar, automotive products, products for their pet, bakery, specialties and much more.
        `,
            },

            {
              direc: "column",
              imgname: "imoview-crm",
              altimg: "Imoview CRM",
              rowspan: 1,
              colspan: 1,
              title: "Imoview CRM",
              text: `Imoview CRM is a customer relationship management system designed for real estate professionals that allows them to connect with their clients in a simple, fast, and digital way.
        My contribution to the project focused on user experience and interface design, ensuring that Imoview CRM is not only functional but also user-friendly and easy to navigate.`,
            },

            {
              direc: "column",
              imgname: "eleven-rush",
              altimg: "Landing Pages for US Providers",
              rowspan: 1,
              colspan: 1,
              title: "Landing Pages for US Providers",
              text: `As a UX Designer and Front End Developer, my contribution to the Eleven Rush project focused on creating landing pages and email marketing campaigns for telecommunications providers as Frontier, AT&T and CenturyLink.
        I worked to design user-friendly and visually appealing landing pages that effectively communicated the benefits of the telecommunications providers' services. I also developed email marketing campaigns that were targeted and engaging, helping to drive conversions and increase customer loyalty.
        `,
            },

            {
              direc: "column",
              imgname: "vero-steel",
              altimg: "Vero Steel",
              rowspan: 1,
              colspan: 1,
              title: "Vero Steel",
              text: `The Vero Steel project was developed by the information technology company Vetta Group. The purpose of the tool is to aid in the management of energy expenditure for steel industry corporations. The system assists with optimizing energy usage in a manner that decreases waste and reduces associated costs.
        I chose to prioritize functionality and clarity when designing the user interface and overall experience. My goal was to give facility managers a clear, uncomplicated view of important usage statistics and control configurations to help them implement energy efficiency measures. To achieve this, I used a simplified, intuitive layout for the platform that makes relevant information and tools easy to access and understand.
        `,
            },

            {
              direc: "column",
              imgname: "collab-english",
              altimg: "Collab English",
              rowspan: 1,
              colspan: 2,
              title: "Collab English",
              text: `Collab English is an English school in Brazil that prioritizes student interaction, even in remote classes.
        As a UX/UI designer and front-end developer, my role in the Collab English project was to create an intuitive and visually appealing website. I focused on designing user-friendly layouts, implementing responsive design principles, and optimizing platform performance for seamless interaction across various devices.
        `,
            },
          ].map((obj) => (
            <Image
              imgname={obj.imgname}
              title={obj.title}
              text={obj.text}
              altimg={obj.altimg}
              rowspan={obj.rowspan}
              colspan={obj.colspan}
              direc={obj.direc}
            />
          ))}
        </Grid>
      </Flex>
      <Flex
        pos={"absolute"}
        h={{ base: "140px", sm: "140px", md: "140px" }}
        w={"100%"}
        m={"-139px 0 0 0"}
        backgroundImage={curve}
        bgRepeat={"repeat-x"}
        bgPos={"center center"}
        zIndex={4}
      ></Flex>
    </>
  );
};

export default Showcase;
