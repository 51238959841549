import { Text, Flex, Box, ChakraProvider, Heading } from "@chakra-ui/react";
import Footer from "../../components/molecules/Footer";
import HillsDivider from "../../components/atoms/HillsDivider";
import LinksBox from "../../components/molecules/LinksBox";
import LogoHeader from "../../components/atoms/Logo";
import PreLoader from "../../components/PreLoader";

const LinksPage = (props) => {
  return (
    <>
      <PreLoader />
      <ChakraProvider>
        <Flex m={"20px 0 40px"} justifyContent={"center"}>
          <LogoHeader />
        </Flex>
        <Flex
          textAlign={"center"}
          align={"center"}
          justify={"center"}
          flexDir={"column"}
          zIndex={4}
        >
          <Heading className="hero-title" as="h1" size="2xl">
            Hi! I Am
            <br />
            <span className="highlight">Nathan Martins</span>
          </Heading>
          <Text m={"20px 25px"} maxW={"600px"} className="hero-subtitle">
            <span className="bold-text">Advertiser and Front-End Developer</span> specialized in{" "}
            <span className="bold-text">UX Design</span>. I love to bring ideas to life through
            designs that inspire, communicate, and connect people.
          </Text>
        </Flex>
        <Box m={"25px 0 117px"}>
          <Flex pos={"relative"} justifyContent={"center"} alignItems={"center"} zIndex={4}>
            <LinksBox />
          </Flex>
          <HillsDivider zindex={0} m={"-300px 0 0 0"} />
        </Box>
        <Flex
          bg={"#115945"}
          h={100}
          pos={"relative"}
          justifyContent={"center"}
          alignItems={"center"}
          zIndex={4}
        ></Flex>
        <Footer />
      </ChakraProvider>
    </>
  );
};

export async function getStaticPaths() {
  return {
    paths: [{ params: { slug: "links" } }],
    fallback: false,
  };
}

export async function getStaticProps({ params }) {
  // Fetch any necessary data for the Links page
  // and return it as props
  return {
    props: {},
  };
}
export default LinksPage;
