const JourneyData = [
  {
    id: 1,
    date: "2023",
    imgURL: "./port-imgs/luxura-spa-cafe-menu.jpg",
    imgURL2: "./port-imgs/luxura-spa-instagram-posts.jpg",
    imgURL3: "./port-imgs/luxura-spa-instagram-stories.jpg",
    title: "Visual Designer",
    company: "Luxura Spa",
    location: "New York, NY (Remote)",
    description: `As a visual designer for Luxura Spa, I was responsible for creating engaging and cohesive visual content across their brand channels. This included designing social media posts and stories to promote their services and drive traffic, conceptualizing and executing email marketing campaigns to nurture customer relationships, developing aesthetically pleasing and conversion-focused landing pages, and editing videos for social media to showcase the Luxura experience.
    
        My contributions helped Luxura Spa to connect with customers, and achieve marketing planned goals. I leveraged my skills in graphic design, copywriting, and video production to produce versatile visual assets tailored for each channel and campaign.`,
  },
  {
    id: 2,
    date: "2022 - 2023",
    imgURL: "./port-imgs/sites-providers.jpg",
    imgURL2: "./port-imgs/site-meu-credito-liberado.jpg",
    imgURL3: "./port-imgs/site-cleverping.jpg",
    title: "UX Designer",
    company: "ElevenRush",
    location: "São Paulo, SP (Hybrid)",
    description: `To deliver provider's landing pages and sites faster, lighter and optimized for SEO, I opted to use React JS and Chackra UI. This not only provided a more efficient and intuitive experience for users, but it also gave us the opportunity to make sure that the pages and sites were optimized for search engine optimization.
    
        In addition, through the e-mail marketing campaigns that we were running, we were able to acquire leads at a lower cost, helping to maximize the return on investments. This, in addition to the optimization of the landing pages and sites, allowed us to further increase our visibility and reach to potential customers.`,
  },
  {
    id: 3,
    date: "2019 - 2020",
    imgURL: "./port-imgs/site-wippe.jpg",
    imgURL2: "./port-imgs/app-wippe.jpg",
    imgURL3: "./port-imgs/site-endolog.jpg",
    title: "Web Design Analyst",
    company: "SP Intervention",
    location: "São Paulo, SP (Hybrid)",
    description: `By making UI improvements in the Wippe App and creating instructional video tutorials, we were able to make it much more accessible to our target audience. In addition, I produced social media content, landing pages, and e-books to deliver helpful tips, news, and additional content related to healthcare to B2C leads.
    
        We also designed event stands, brochures, and email marketing to present our telemedicine, prostheses, and related products to B2B partners. This all helped to effectively promote our product and services, and in turn, increase our user base.`,
  },
  {
    id: 4,
    date: "2017 - 2018",
    imgURL: "./port-imgs/mateus-mateus-app.jpg",
    imgURL2: "./port-imgs/flyer-beleza-e-higiene.jpg",
    imgURL3: "./port-imgs/mateus-mapa-logo-banner.jpg",
    title: "Marketing Analyst",
    company: "Grupo Mateus",
    location: "São Luís, MA (Full-Time)",
    description: `I implemented standardized processes based on design thinking methodologies, automating time-consuming activities and standardizing communication between designers and account managers. These improvements led to a significant acceleration in the team's productivity, by a factor of three.
    
        Additionally, as a videomaker, I covered events and store openings, helping to maintain engagement and feed social media channels. By creating engaging videos that showcased our products and services, we were able to attract more customers and drive sales.`,
  },
  {
    id: 5,
    date: "2016",
    imgURL: "./port-imgs/universal-software-infographics.jpg",
    imgURL2: "./port-imgs/universalsoftware-imoview-landing-page.jpg",
    imgURL3: "./port-imgs/universalsoftware-imoview-interface.jpg",
    title: "Graphic Designer",
    company: "Universal Software",
    location: "Belo Horizonte, MG (Part-Time)",
    description: `I made significant contributions to the success of events involving real estate businessmen. I was responsible for creating visually effective presentation materials, providing photographic coverage, and producing engaging videos for social media and blogs. Additionally, my expertise in developing optimized landing pages and eBooks helped to capture valuable leads and drive growth for the business.
    
        Through my dedication in delivering high-quality work and my ability to collaborate effectively with team members, I was able to help the business to establish a strong online presence and achieve marketing success.`,
  },
];

export default JourneyData;
