import * as React from "react";
import { Container, Stack, Text, Flex } from "@chakra-ui/react";
import CarouselHero from "../atoms/CarouselHero";
import Sun from "../atoms/Sun";
import HillsDivider from "../atoms/HillsDivider";
import "../../styles/hero.css";

const HeroBackground = () => {
  return (
    <Flex
      pos="absolute"
      zIndex={1}
      opacity={0.2}
      aspectRatio={1}
      bgPos="center"
      backgroundSize="contain"
      w={{ base: "914px", md: "914px" }}
      h={"914px"}
      top={"-40em"}
      left={{ base: "-40em", sm: "-28em", md: "-30em" }}
      rounded="full"
      blendMode="multiply"
    />
  );
};

const HeroContent = () => {
  return (
    <Stack spacing={3} direction={{ base: "column", md: "row" }} justifyContent="center">
      <Flex
        flexDir="column"
        spacing={3}
        alignSelf="center"
        maxW="500px"
        mx={{ base: "25px", md: "0" }}
        textAlign={{ base: "center", md: "left" }}
      >
        <h1 className="hero-title">
          Hi! I Am
          <br />
          <span className="highlight">Nathan Martins</span>
        </h1>
        <Text className="hero-subtitle">
          <span className="bold-text">Advertiser and Front-End Developer</span> specialized in{" "}
          <span className="bold-text">UX Design</span>. I love to bring ideas to life through
          designs that inspire, communicate, and connect people.
        </Text>
      </Flex>
      <Flex
        maxW={"500px"}
        maxH={"500px"}
        mt={{ base: "4rem", md: 0 }}
        ml={{ base: 0, md: 2 }}
        display="flex"
        alignSelf="center"
      >
        <CarouselHero />
        <Sun />
      </Flex>
    </Stack>
  );
};

const Hero = () => {
  return (
    <>
      <HeroBackground />
      <Container maxW={"1200px"} px={{ base: 4, md: 4 }} pt={20}>
        <HeroContent />
      </Container>
      <HillsDivider zindex={4} m={"-180px 0 0 0"} />
    </>
  );
};

export default Hero;
