import {
  Box,
  Container,
  Link,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import LogoHeader from "../atoms/Logo";
import { BsLinkedin, BsFacebook, BsInstagram } from "react-icons/bs";

const Logo = () => {
  return <LogoHeader />;
};

export default function Footer() {
  return (
    <>
      <Box bgColor={"#E5D5B8"} color={useColorModeValue("gray.700", "gray.200")}>
        <Container maxW={"6xl"} pt={10}>
          <Stack
            flexDirection={{ base: "column", md: "row" }}
            spacing={6}
            justify={"space-between"}
          >
            <Text textAlign={{ base: "center", md: "start" }}>
              I am Nathan Martins, an Advertiser especialized in <strong>UX Design</strong>.<br />I
              love to bring ideas to life through designs that inspires, communicates, and connects
              people.
            </Text>
            <Stack align={"center"} fontSize={"3xl"}>
              <HStack>
                <Link href="https://www.linkedin.com/in/nathanmls/">
                  <BsLinkedin />
                </Link>

                <Link href="https://www.facebook.com/nathanmls">
                  <BsFacebook />
                </Link>

                <Link href="https://www.instagram.com/nathanmls/">
                  <BsInstagram />
                </Link>
              </HStack>
            </Stack>
          </Stack>
        </Container>
        <Box py={10}>
          <Flex
            align={"center"}
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: useColorModeValue("#D1BB91", "#D1BB91"),
              flexGrow: 1,
              mr: 8,
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: useColorModeValue("#D1BB91", "#D1BB91"),
              flexGrow: 1,
              ml: 8,
            }}
          >
            <Logo />
          </Flex>
          <Text pt={6} fontSize={"sm"} textAlign={"center"}>
            © 2023 Nathan Martins. All rights reserved
          </Text>
        </Box>
      </Box>
    </>
  );
}
