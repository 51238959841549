import React from "react";
import { Box } from "@chakra-ui/react";
import mountain from "../../assets/images/mountain.png";

const HillsDivider = ({ m, zindex }) => {
  return (
    <Box
      pos={"absolute"}
      h={"417px"}
      w={"100%"}
      m={m}
      backgroundImage={mountain}
      bgRepeat={"repeat-x"}
      bgPos={"top center"}
      zIndex={zindex}
    ></Box>
  );
};

export default HillsDivider;
