import React from "react";
import { Box, Image } from "@chakra-ui/react";
import profile from "../../assets/images/nathan-martins-profile-picture.jpg";

const ProfilePicture = () => {
  return (
    <Box
      mb={{ base: "30px", md: 0 }}
      mr={{ base: "16px", md: 5 }}
      pos="relative"
      overflow={"hidden"}
      w={{ base: "60vw", md: "40vw", xl: "30vw" }}
      h={{ base: "60vw", md: "40vw", xl: "30vw" }}
      roundedTop="full"
      roundedBottom={200}
      shadow="18px 18px 0 #DD9331"
    >
      <Image src={profile} alt="Nathan Martins Profile Picture" />
    </Box>
  );
};

export default React.memo(ProfilePicture);
