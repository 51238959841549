import { motion } from "framer-motion";
import { Box, Image } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const CarouselJourney = ({ imgURL, imgURL2, imgURL3 }) => {
  // Move hooks to the top level, before any conditional returns
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [imgURL, imgURL2, imgURL3].filter(Boolean); // Filter out falsy values

  // Auto-cycle effect
  useEffect(() => {
    if (images.length <= 1) return; // Don't cycle if there's only one or no images

    const interval = setInterval(() => {
      setCurrentImageIndex((current) => (current + 1) % images.length);
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  // Early return if no images
  if (!images.length) {
    return (
      <Box
        p={{ base: 0, md: 10 }}
        w={{ base: "40vw", sm: "50vw", md: "50vw", lg: "50vw", xl: "50vw" }}
        bg="transparent"
      />
    );
  }

  return (
    <motion.div
      style={{
        width: "100%",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <motion.div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {images.map((image, index) => (
          <motion.div
            key={index}
            style={{
              position: index === currentImageIndex ? "relative" : "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            initial="enter"
            animate={index === currentImageIndex ? "center" : "exit"}
            variants={variants}
            transition={{
              opacity: { duration: 1 }, // 1 second cross-fade
              ease: "easeInOut",
            }}
          >
            <Image
              rounded={15}
              src={image}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default CarouselJourney;
