import React, { useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Textarea,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { BsPerson, BsChat, BsSend } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import axios from "axios";

const ContactForm = ({ buttonbg, butoncolor }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Create a FormData object and populate it with form data
    const formData = new FormData(e.target);

    try {
      const response = await axios.post(
        "https://nathandesign.art/submit-form", // Replace with your actual endpoint URL
        {
          FNAME: formData.get("FNAME"),
          EMAIL: formData.get("EMAIL"),
          MESSAGE: formData.get("MESSAGE"),
        }
      );

      if (response.status === 200) {
        setSubmissionStatus("success");
      } else {
        setSubmissionStatus("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmissionStatus("error");
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <BsPerson color="#999999" />
          </InputLeftElement>
          <Input type="text" name="FNAME" placeholder="Name" bg="white" required />
        </InputGroup>

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <MdEmail color="#999999" />
          </InputLeftElement>
          <Input type="email" name="EMAIL" placeholder="Email" bg="white" required />
        </InputGroup>

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <BsChat color="#999999" />
          </InputLeftElement>
          <Textarea
            name="MESSAGE"
            placeholder="Message"
            pl="40px"
            minH="100px"
            bg="white"
            required
          />
        </InputGroup>

        <Button
          type="submit"
          bg={buttonbg}
          color={butoncolor}
          _hover={{ bg: "#A45826" }}
          rightIcon={<BsSend />}
          w="full"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Send
        </Button>
      </Stack>
      {submissionStatus === "success" && (
        <Alert mt={4} rounded={"md"} status="success">
          <AlertIcon />
          Thank you for reaching out!.
        </Alert>
      )}
      {submissionStatus === "error" && (
        <Alert mt={4} rounded={"md"} status="error">
          <AlertIcon />
          There was an error sending your message. Please try again.
        </Alert>
      )}
    </form>
  );
};

export default ContactForm;
