import React from "react";
import { Container, Stack, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import ProfilePicture from "../atoms/ProfilePicture";
import AboutText from "../atoms/AboutText";
import SkillsSection from "../atoms/SkillsSection";

const About = () => {
  return (
    <Stack
      mt={{ base: "20px", md: "100px" }}
      zIndex={0}
      w="100%"
      h="auto"
      backgroundColor="#115945"
    >
      <VStack zIndex="5" pos="relative" id="aboutme" pb="120px">
        <Container maxW="1200px" px={{ base: 4, lg: 6, md: 6 }} pt={12} zIndex={1}>
          <Stack mb="5rem" direction={{ base: "column", md: "row" }} justifyContent="center">
            <motion.div
              whileInView={{ opacity: 1, y: "0" }}
              transition={{ ease: "linear", delay: 0.2 }}
              h="100%"
              style={{ opacity: 0, y: "2vw" }}
              align={"center"}
            >
              <ProfilePicture />
            </motion.div>
            <motion.div
              whileInView={{ opacity: 1, y: "0" }}
              transition={{ ease: "linear", delay: 0.4 }}
              h="100%"
              style={{ opacity: 0, y: "2vw" }}
            >
              <AboutText />
            </motion.div>
          </Stack>

          <SkillsSection />
        </Container>
      </VStack>
    </Stack>
  );
};

export default React.memo(About);
