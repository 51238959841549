import React from "react";
import { SimpleGrid, Box, Heading, Text, Image, Tooltip } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import grainbgbox from "../../assets/images/grain-box.png";

const SkillsSection = () => {
  const skillSections = [
    {
      title: "Front-Page Dev and SEO",
      icons: ["html", "css", "seo", "react", "wordpress", "js"],
    },
    {
      title: "UX/UI + Design Thinking",
      icons: ["figma", "adobe-xd", "hotjar"],
    },
    {
      title: "Visual Softwares",
      icons: [
        "affinity-designer",
        "affinity-photo",
        "photoshop",
        "illustrator",
        "premiere",
        "adobe-xd",
        "after-effects",
      ],
    },
    {
      title: "3D",
      icons: ["blender", "dimension", "sketchup"],
    },
    {
      title: "AI Tools",
      icons: ["chat-gpt-openai", "midjourney", "microsoft-designer"],
    },
  ];

  return (
    <AnimatePresence>
      <SimpleGrid
        direction={{ base: "column", md: "row" }}
        verticalAlign="baseline"
        columns={{ base: "1", sm: "2", md: "3", lg: "5" }}
        spacing="30px"
        justifyContent="center"
        key="skills-grid" // Add a unique key
      >
        {skillSections.map((skill, index) => (
          <motion.div
            key={index}
            whileInView={{ opacity: 1, y: "0" }}
            transition={{ ease: "linear", delay: 0.2 * index }}
            h="100%"
            style={{ opacity: 0, y: "2vw" }}
          >
            <SkillSection skill={skill} />
          </motion.div>
        ))}
      </SimpleGrid>
    </AnimatePresence>
  );
};

const SkillSection = React.memo(({ skill }) => {
  return (
    <Box color="#F9F0DF" textAlign="center" mb={{ base: "30px", md: 0 }} pos="relative">
      <Box
        w={{ base: "210px", lg: "100%" }}
        display="inline-grid"
        border="3px solid rgba(0, 0, 0, 0.26);"
        mb="10px"
        rounded="15px"
        backgroundImage={grainbgbox}
        backgroundBlendMode="multiply"
        backgroundPosition="center"
        backgroundColor="#115945"
        backgroundSize="cover"
      >
        <SimpleGrid display="inline-grid" columns="3" spacingX="13px" spacing="13px" p="4">
          {skill.icons.map((icon) => (
            <Tooltip
              key={icon}
              label={icon.replace("-", " ").toUpperCase()}
              aria-label={icon.replace("-", " ").toUpperCase()}
            >
              <Image
                _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                src={`./skills-icons/${icon}.svg`}
                alt={icon.toUpperCase()}
              />
            </Tooltip>
          ))}
        </SimpleGrid>
      </Box>
      <Heading mb="10px" size="md">
        {skill.title}
      </Heading>
      <Text fontSize="small">{skill.icons.map((icon) => icon.replace("-", " ")).join(", ")}</Text>
    </Box>
  );
});

export default React.memo(SkillsSection);
