import { chakra, HStack, VStack, Text, Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const Card = ({ title, company, description, date, location }) => {
  return (
    <HStack
      pl={0}
      bg={useColorModeValue("none", "none")}
      spacing={20}
      rounded="lg"
      alignItems="top"
      pos="relative"
      h={"100%"}
    >
      <VStack h={"100%"} spacing={2} textAlign="left">
        <Text fontSize="xl" fontWeight="600" w="100%" color={"#A45826"}>
          {date}
        </Text>
        <chakra.h1 color={"#1D4036"} fontSize="3xl" lineHeight={0.5} fontWeight="bold" w="100%">
          {title}
        </chakra.h1>
        <Text fontSize="lg" fontWeight="600" w="100%" color={"#A45826"}>
          {company}
          <Box fontSize="md" color={"#888"}>
            {location}
          </Box>
        </Text>
        <Text color={"#1D4036"} whiteSpace={"pre-line"} fontSize="md">
          {description}
        </Text>
      </VStack>
    </HStack>
  );
};

export default Card;
